<template>
    <div>
      <section>
          <article class="contact_body pb-5">
            <div class="container">
              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                  <h2
                    class="text-center"
                    style="font-size: 1.5em; font-weight: 600"
                  >
                    We value your experience—please take a moment to share your feedback on the room you stayed in.
                  </h2>
                  
                </div>
                <div class="col-md-2"></div>
              </div>
  
              <section>
                <article>
                  <div class="contact_form">
                    <div class="row">
                      <div class="col-md-2"></div>
                      <div class="col-md-8">
                        <el-form
                          :model="reviewForm"
                          :rules="rules"
                          ref="reviewForm"
                          label-width="120px"
                          class="demo-reviewForm"
                          label-position="top"
                        >
                          <div class="row mb-5">
                            <div class="col-md-12">
                              <el-form-item label="Rate Us" prop="rating">
                                <el-rate
                                  v-model="reviewForm.rating"
                                  :colors="colors">
                                </el-rate>
                              </el-form-item>
                            </div>
                          </div>
                          
                          <div class="row mt-5">
                            <div class="col-md-12">
                              <el-form-item label="Review Message" prop="reviewMessage">
                                <el-input
                                  type="textarea"
                                  v-model="reviewForm.reviewMessage"
                                  placeholder="Message..."
                                  :autosize="{ minRows: 10, maxRows: 20}"
                                  maxlength="1000"
                                  show-word-limit
                                ></el-input>
                              </el-form-item>
                            </div>
                          </div>

                          <div class="text-center">
                            <el-form-item class="m-0">
                              <el-button
                                class="content-btn"
                                @click="submitForm('reviewForm')"
                                >Submit FeedBack <i class="el-icon-s-promotion"></i></el-button
                              >
                            </el-form-item>
                          </div>
                        </el-form>
                      </div>
                      <div class="col-md-2"></div>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </article>
        </section>
  
    </div>
  </template>
  
  <script>
  export default {
  
    data() {
      return {
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'], // same as { 2: '#99A9BF', 4: { value: '#F7BA2A', excluded: true }, 5: '#FF9900' }
      
        reviewForm: {
          rating: '',
          reviewMessage: '',
        },

        rules: {
          rating: [
            {
              required: true,
              message: 'Please input Rating',
              trigger: 'blur',
            },
          ],
          reviewMessage: [
            {
              required: true,
              message: 'Please input reviewMessage',
              trigger: 'blur',
            },
          ],
        },
      
      };
    },
  
    methods: {
      async submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            
            try {
              let request = await this.$http.post(
                `reviews`, {
                  rating: this.reviewForm.rating,
                  reviewMessage: this.reviewForm.reviewMessage,
                  roomID: this.$route.params.roomID,
                  reviewType: 'ROOM'
                }
              );
              if (
                request.data.success &&
                request.data.message == "REVIEW_CREATED_SUCCESSFULLY"
              ) {
                this.resetForm(formName);
                return this.$notify.success({
                  title: 'Success',
                  message: 'Your Feedback has been received successfully'
                });
  
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify.error({
                  title: 'Connection failed',
                  message: 'A network error occurred, please try again.'
                });
              }
              this.$notify.error({
                  title: 'Loading Failed',
                  message: 'Unable to send Feedback, Please try again later'
                });
            } finally {
              this.loading = false;
            }
          } else {
            this.$notify.error({
              title: 'Error',
              message: 'Unable to send this message.'
            });
            return false;
          }
        });
      },
  
  
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    * {
      font-family: 'Lato', sans-serif;
    }
  
  .contact_body {
    background-color: #F6E0BF;
    padding-top: 200px;
  }
  
  .contact_form {
    background-color: #fcf9f5;
    margin-top: 20px;
    padding: 50px 50px;
  }
  
  .content-btn {
    background-color: #3D290A;
    color: white;
    border: 2px solid #3D290A;
    border-radius: 0px;
    font-size: 1.2em;
    margin: 0px !important;
    transition: 0.5s;
    width: 250px;
  }
  
  .content-btn:hover {
    background-color: transparent;
    color: #3D290A;
    transition: 0.5s;
  }
  
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) { }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }
  
  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) { }
  
  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) { }
  </style>


<style>
  .el-form-item__label {
      font-size: 1.5em;
      color: #606266;
      padding: 0 12px 0 0;
  }
  .el-rate__icon {
    font-size: 58px;
    margin-right: 6px;
    color: #C0C4CC;
    transition: .3s;
  }

  .el-rate {
    height: 50px;
    line-height: 1;
  }
</style>
  